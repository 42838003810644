import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

@Injectable()
export class MyHttpClient {
  constructor(private http: HttpClient) {}

  get(url, config = {}) {
    return this.http.get(url, config)
  }

  post(url, data) {
    return this.http.post(url, data)
  }
}
