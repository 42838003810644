import { Injectable } from '@angular/core';
import { MyHttpClient } from '../http-client';
import { environment } from '../../environments/environment'


@Injectable()
export class ShareRideHomeService {
  server: string = environment.SERVER;

  trackUrl =process.env.SERVER+'/api/track/';
 
  constructor(private http: MyHttpClient) {
    }


  getTrackingData(trackId){
    return this.http.get(this.trackUrl+trackId);
  }

}
