import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core'
import { Observable } from 'rxjs/Rx'
import { RIDE_TYPES } from '../shared/constants'
import { ImageViewService } from '../image-view.service'

@Component({
  selector: 'ride-details',
  template: require('./ride-details.component.html'),
})
export class RideDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input('data') data: any;
  @Output() reload: EventEmitter<any> = new EventEmitter();
  private S3_URL_REGEX: RegExp
  private GCS_URL_REGEX: RegExp
  public name: string;
  public number: string;
  public avgRating: number;
  public vehicleModel: string;
  public vehicleNumber: string;
  public currentTime: Date = new Date();
  public updatedTime: Date = new Date();
  public lastUpdated = 0;
  public orderType: string;
  public profilePicUrl: string;
  public dropAddress: string;
  public pickupAddress: string;
  public status: string;
  public eta = 0;
  public dropOTP: number;
  public otp: number;
  public subscriber: any;
  public ownerName: string;
  public customerName: string;
  public rideOrOrder: string;
  public helpLineNumber: string;
  public locationDetailsExpanded = false;
  public OfferDetailsExpanded = false;
  public otpString: string;
  public amount: number;
  private isImageLoading = false;
  public stauses = ['onTheWay', 'arrived', 'started'];
  public SOSImage: string = '../../../assets/img/SOS.svg';
  public bookForSomeoneElse: any;
  public subTotal: number;
  public offerApplied: boolean;
  public offerName: string;
  public offerCode: string;
  public droppedAt: string;
  public mode: string;
  public paymentImage: string;
  modeImageMap = {
    'gpay': 'https://rapido-app-assets.storage.googleapis.com/e90384d9d0103a6527aa10069db377d7_1714998369948.svg',
    'cash': 'https://rapido-app-assets.storage.googleapis.com/e4e126e04a0ccad01f5ad57f460005c3_1714992053641.svg',
    'upi': 'https://rapido-app-assets.storage.googleapis.com/e90384d9d0103a6527aa10069db377d7_1714998369948.svg',
    'rapido': 'https://rapido-app-assets.storage.googleapis.com/c070f35a2e94188ac65d9ec75b0a7f94_1714994630493.svg',
    'simpl': 'https://rapido-app-assets.storage.googleapis.com/a0532d342d5b55f7147b63afbc710b79_1714994636443.svg',
    'amazonpay': 'https://rapido-app-assets.storage.googleapis.com/f1d70f8f5de1bad37aee1c8bf99077d5_1714994616166.svg',
    'paytm': 'https://rapido-app-assets.storage.googleapis.com/6169562899cacd4a17770cc67f6dddb3_1714994626952.svg',
    'lazypay': 'https://rapido-app-assets.storage.googleapis.com/851b9a003656f4376d3a96df77601cf5_1714994620795.svg',
    'qrcode': 'https://rapido-app-assets.storage.googleapis.com/e4e126e04a0ccad01f5ad57f460005c3_1714992053641.svg',
    'corporate': 'https://rapido-app-assets.storage.googleapis.com/c070f35a2e94188ac65d9ec75b0a7f94_1714994630493.svg', 
    // Add more modes and their corresponding image URLs as needed
  };
  constructor(private _imageViewService: ImageViewService) {
    this.S3_URL_REGEX = new RegExp(/(s3-|s3\.)?(.*)\.amazonaws\.com/)
    this.GCS_URL_REGEX = new RegExp(/(.*)\.storage\.googleapis\.com/)
   }

  ngOnInit() {
    this.subscriber = Observable.interval(500).subscribe(() => {
      this.currentTime = new Date();
      this.lastUpdated = this.currentTime.getMinutes() - this.updatedTime.getMinutes();
      this.lastUpdated = Math.abs(this.lastUpdated);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      if (changes.data.currentValue && changes.data.currentValue.riderObj) {
        const data = changes.data.currentValue;
        this.name = data.riderObj.riderInfo.name;
        this.customerName = data.customerObj.name;
        this.ownerName = RIDE_TYPES.includes(data.orderType) ? this.customerName : 'Captain';
        this.number = data.riderObj.riderInfo.mobile;
        this.setImageUrl(data.riderObj.riderInfo.profilePicture)
        this.orderType = data.orderType;
        this.vehicleNumber = data.riderObj.vehicleObj.number;
        this.vehicleModel = data.riderObj.vehicleObj.make;
        this.dropAddress = data.dropLocation.address;
        this.pickupAddress = data.pickupLocation.address;
        this.status = data.status;
        this.eta = data.riderLocation && data.riderLocation.eta || this.eta;
        this.updatedTime = new Date();
        this.avgRating = data.riderObj && data.riderObj.avgRating || null;
        this.dropOTP = data.dropOTP;
        this.otp = data.otp;
        document.title = this.customerName ? this.customerName + '\'s Rapido' : 'Rapido';
        this.helpLineNumber = data.helpLineNumber;
        this.bookForSomeoneElse = data.bookForSomeoneElse;
        this.otpString = this.otp ? this.otp.toString() : null;
        this.amount = data.pricing.amount;
        this.subTotal = data.pricing.subTotal;
        this.offerApplied = data.pricing.offer.applied;
        this.offerName = this.offerApplied ? data.pricing.offer.name : '';
        this.offerCode = this.offerApplied ? data.pricing.offer.code : '';
        this.droppedAt = data.droppedAt;
        this.mode = data.payment.mode;
     }
    }
  }

  isS3Url(hostname: string) {
    return this.S3_URL_REGEX.test(hostname);
  }

  isGCSUrl(hostname: string) {
    return this.GCS_URL_REGEX.test(hostname);
  }

  setImageUrl(url) {
    // guard clause (no-op)
    if (!url || this.profilePicUrl || this.isImageLoading) {
      return
    }

    const { hostname, pathname, search } = new URL(url)
    if (this.isS3Url(hostname) || this.isGCSUrl(hostname)) {
      this.profilePicUrl = url
    } else {
      this.isImageLoading = true
      this._imageViewService.getPreSignedUrl(pathname, search).subscribe(
        (preSignedUrl) => {
          this.isImageLoading = false
          this.profilePicUrl =  preSignedUrl['url']
        }
      )
    }
  }

  toggleLocationExpansion() {
    this.locationDetailsExpanded = !this.locationDetailsExpanded;
    setTimeout(() => {
      if (this.locationDetailsExpanded) {
        const element = document.getElementById('location-details');
        element.scrollIntoView(true);
      }
    }, 200);
  }

  reloadData() {
    this.reload.emit(true);
  }

  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

}
