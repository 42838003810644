import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppComponent } from '../../src/app/app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatButtonModule} from '@angular/material/button';

import { ToastSnackbar } from './toast-message/toast-message.service';



import {MatIconModule} from '@angular/material/icon';

import {MyHttpClient } from './http-client'
import { ShareRideHomeComponent } from './share-ride-home/share-ride-home.component';
import { RideDetailsComponent } from './ride-details/ride-details.component';

import { GoogleMapComponent } from './google-map/google-map.component';
import { LiveStatusUpdateComponent } from './live-status-update/live-status-update.component';




@NgModule({
  declarations: [
    AppComponent,
    ToastSnackbar,
    ShareRideHomeComponent,
    RideDetailsComponent,
    GoogleMapComponent,
    LiveStatusUpdateComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
     MatSnackBarModule,
     MatIconModule,
     MatButtonModule
  ],
  entryComponents: [
   ToastSnackbar
  ],
  providers: [
    MyHttpClient
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
