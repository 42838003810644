import { ChangeDetectorRef, Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { } from 'googlemaps';

@Component({
  selector: 'google-map',
  template: require('./google-map.component.html')
})

export class GoogleMapComponent implements OnInit, OnChanges {
  private polyline: google.maps.Polyline;
  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;
  lat = 12.911968;
  lng = 77.647221;
  mapZoom = 15;

  riderMarkerIcon = '../../../assets/img/Group.svg';
  pickupMarkerIcon = '../../../assets/img/Pickup_marker.svg';
  dropMarkerIcon = '../../../assets/img/Drop_marker.svg';
  batchedDropPointMarkerIcon = '../../../assets/img/batchedDropPoints.svg';
  vehicleIcon = {
    url: this.riderMarkerIcon, // url
    scaledSize: new google.maps.Size(24, 32)
  };
  dropIcon = {
    url: this.dropMarkerIcon, // url
    scaledSize: new google.maps.Size(40, 50)
  };
  pickupIcon = {
    url: this.pickupMarkerIcon, // url
    scaledSize: new google.maps.Size(40, 50)
  };
  batchedDropPointIcon = {
    url: this.batchedDropPointMarkerIcon, // url
    scaledSize: new google.maps.Size(20, 30)
  };
  riderMarker = new google.maps.Marker();
  pickupMarker = new google.maps.Marker();
  dropMarker = new google.maps.Marker();
  batchedDropPoints = [];

  @Input('data') data: any;
  public status: string;
  public riderLocation: any;
  public pickupLocation: any;
  public dropLocation: any;
  public mapStyle = {
    rapido: [
    {
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#f5f5f5'
        }
      ]
    },
    {
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#8f8e8f'
        },
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'elementType': 'labels.text.stroke',
      'stylers': [
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'administrative.land_parcel',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#bdbdbd'
        }
      ]
    },
    {
      'featureType': 'landscape',
      'elementType': 'geometry.fill',
      'stylers': [
        {
          'color': '#edeef0'
        },
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'poi',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#eeeeee'
        }
      ]
    },
    {
      'featureType': 'poi',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#757575'
        }
      ]
    },
    {
      'featureType': 'poi.attraction',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'color': '#83a3ba'
        },
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'poi.business',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'color': '#7aa4bd'
        },
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'poi.government',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'poi.medical',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'color': '#83a3ba'
        },
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#e5e5e5'
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'geometry.fill',
      'stylers': [
        {
          'color': '#beebc8'
        },
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'color': '#83a3ba'
        },
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#8c8a8d'
        },
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'labels.text.stroke',
      'stylers': [
        {
          'color': '#fefcff'
        },
        {
          'visibility': 'on'
        },
        {
          'weight': 0.5
        }
      ]
    },
    {
      'featureType': 'poi.place_of_worship',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'poi.school',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'road',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#ffffff'
        }
      ]
    },
    {
      'featureType': 'road.arterial',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#757575'
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#dadada'
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'geometry.fill',
      'stylers': [
        {
          'color': '#fff9c4'
        },
        {
          'saturation': -20
        },
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#616161'
        }
      ]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'labels.text.stroke',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'road.local',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#9e9e9e'
        }
      ]
    },
    {
      'featureType': 'transit',
      'stylers': [
        {
          'saturation': -100
        },
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'transit.line',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#e5e5e5'
        }
      ]
    },
    {
      'featureType': 'transit.station',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#eeeeee'
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#c9c9c9'
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'geometry.fill',
      'stylers': [
        {
          'color': '#b5dfff'
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'labels.text',
      'stylers': [
        {
          'color': '#f2f3fc'
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#648699'
        },
        {
          'visibility': 'on'
        }
      ]
    }
  ]};
  bound = new google.maps.LatLngBounds();
  constructor(private cdr: ChangeDetectorRef) {
  }
  // let marker = new google.maps.Marker({position: {lat: this.lat, lng: this.lng}, map: this.map});
    ngOnChanges(changes: SimpleChanges) {
      if (changes.data) {
        this.data = changes.data.currentValue;
        if (this.data) {
          if (this.data.polyline) {
            const encodedStr = this.data.polyline;
            const finalPath = google.maps.geometry.encoding.decodePath(encodedStr);

            if (this.polyline) {
              this.polyline.setMap(null);
              this.recenterMap();
            }

            this.polyline = new google.maps.Polyline({
              path: finalPath,
              geodesic: true,
              strokeColor: '#000000',
              strokeOpacity: 1.0,
              strokeWeight: 3
            });
            this.polyline.setMap(this.map);
            this.recenterMap();
            this.cdr.detectChanges();
          }
          this.assignVehicleIcon(this.data.orderType)

          if (this.data.status) {
            this.status = this.data.status;
          }

          if (this.data.pickupLocation) {
            this.pickupLocation = {lat: this.data.pickupLocation.lat, lng: this.data.pickupLocation.lng};
            this.pickupMarker.setPosition(this.pickupLocation);
          }

          if (this.data.dropLocation) {
            this.dropLocation = {lat: this.data.dropLocation.lat, lng: this.data.dropLocation.lng};
            this.dropMarker.setPosition(this.dropLocation);
          }

          if (this.data.riderLocation) {
            this.riderLocation = {lat: this.data.riderLocation.lat, lng: this.data.riderLocation.lng};
            this.riderMarker.setPosition(this.riderLocation);
          }

          if (this.data.setMapCenter) {
            if (this.status === 'onTheWay') {
                this.extendBounds(this.riderLocation);
                this.extendBounds(this.pickupLocation);
                this.extendBounds(this.dropLocation);
            } else {
              this.extendBounds(this.dropLocation);
              this.extendBounds(this.riderLocation);
              google.maps.event.trigger(this.map, 'resize');
            }
          }
          
          if (this.data.batchingDropPoints) {
            this.showDropPoints(this.data.batchingDropPoints);
          }

          if (this.data.status === 'dropped') {
            this.riderMarker.setMap(null);
            this.polyline.setMap(null);
          }
        }
      }
    }
    ngOnInit() {
      this.initMap();
    }

    initMap() {
      // const encodedStr = this.data && this.data.polyline;
      // const finalPath = google.maps.geometry.encoding.decodePath(encodedStr);
      const mapProp = {
        center: new google.maps.LatLng(this.lat, this.lng),
        zoom: 15,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        clickableIcons: false,
        zoomControl: false
      };
      this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
      this.map.setOptions({styles: this.mapStyle[0]});
      this.riderMarker.setMap(this.map);
      this.dropMarker.setMap(this.map);
      this.pickupMarker.setMap(this.map);
      this.riderMarker.setIcon(this.vehicleIcon);
      this.riderMarker.setZIndex(99999);
      this.pickupMarker.setIcon(this.pickupIcon);
      this.dropMarker.setIcon(this.dropIcon);
      this.extendBounds(this.data.pickupLocation);
      this.extendBounds(this.data.dropLocation);
      this.extendBounds(this.riderLocation);
    }

    bearingBetweenLocations(latLng1, latLng2) {

      const PI = 3.14159;
      const lat1 = latLng1.lat * PI / 180;
      const long1 = latLng1.lng * PI / 180;
      const lat2 = latLng2.lat * PI / 180;
      const long2 = latLng2.lng * PI / 180;

      const dLon = (long2 - long1);

      const y = Math.sin(dLon) * Math.cos(lat2);
      const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1)
              * Math.cos(lat2) * Math.cos(dLon);

      let brng = Math.atan2(y, x);
      function toDegrees (angle) {
        return angle * (180 / Math.PI);
      }
      brng = toDegrees(brng);
      brng = (brng + 360) % 360;

      return brng;
    }

    showDropPoints (data) {
      this.batchedDropPoints = [];
      for (const point in data) {
        if (data[point] && data[point].lat && data[point].lng) {
          this.batchedDropPoints.push(data[point]);
        }
      }

      if (this.batchedDropPoints.length) {
        for (const icon in this.batchedDropPoints) {
          if (this.batchedDropPoints[icon]) {
            const marker = new google.maps.Marker({
              position: this.batchedDropPoints[icon],
              map: this.map,
              icon: this.batchedDropPointIcon
            });
          }
        }
      }
    }

    assignVehicleIcon(orderType) {
      switch (orderType) {
        case 'auto':
          this.riderMarkerIcon = '../../../assets/img/auto_icon.svg';
          this.vehicleIcon = {
            url: this.riderMarkerIcon,
            scaledSize: new google.maps.Size(40, 40)
          };
          break;
        case 'auto_ncr':
          this.riderMarkerIcon = '../../../assets/img/auto_icon.svg';
          this.vehicleIcon = {
            url: this.riderMarkerIcon,
            scaledSize: new google.maps.Size(40, 40)
          };
          break;
        case 'auto_premium':
          this.riderMarkerIcon = '../../../assets/img/auto_icon.svg';
          this.vehicleIcon = {
            url: this.riderMarkerIcon,
            scaledSize: new google.maps.Size(40, 40)
          };
          break;
        case 'cabEconomy':
          this.riderMarkerIcon = '../../../assets/img/hatchback_icon.svg';
          this.vehicleIcon = {
            url: this.riderMarkerIcon,
            scaledSize: new google.maps.Size(40, 40)
          };
          break;
        case 'cabPremium':
          this.riderMarkerIcon = '../../../assets/img/sedan_icon.svg';
          this.vehicleIcon = {
            url: this.riderMarkerIcon,
            scaledSize: new google.maps.Size(40, 40)
          };
          break;
        case 'cab_economy_share':
          this.riderMarkerIcon = '../../../assets/img/hatchback_icon.svg';
          this.vehicleIcon = {
            url: this.riderMarkerIcon,
            scaledSize: new google.maps.Size(40, 40)
          };
          break;
        case 'bike_lite':
          this.riderMarkerIcon = '../../../assets/img/bike_icon.png';
          this.vehicleIcon = {
            url: this.riderMarkerIcon,
            scaledSize: new google.maps.Size(40, 40)
          };
          break;
        case 'e_rickshaw':
          this.riderMarkerIcon = '../../../assets/img/auto_icon.svg';
          this.vehicleIcon = {
            url: this.riderMarkerIcon,
            scaledSize: new google.maps.Size(40, 40)
          };
          break;
        case 'auto_lite':
          this.riderMarkerIcon = '../../../assets/img/auto_icon.svg';
          this.vehicleIcon = {
            url: this.riderMarkerIcon,
            scaledSize: new google.maps.Size(40, 40)
          };
          break;
      }
    }

    extendBounds(location) {
      this.bound.extend(location);
      this.recenterMap();
    }

    recenterMap() {
      this.map.setCenter(this.bound.getCenter());
      this.map.fitBounds(this.bound);
    }
}
