import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastMessage } from '../toast-message/toast-message.service'
import { ShareRideHomeService } from './share-ride-home.service'
import { Observable } from 'rxjs/Rx';
import { ActivatedRoute } from '@angular/router'
@Component({
  selector: 'share-ride-home',
  template: require('./share-ride-home.component.html'),
  providers: [ToastMessage, ShareRideHomeService]
})
export class ShareRideHomeComponent implements OnInit, OnDestroy {
  public data: any;
  public subscriber: any;
  public showData: boolean = true;
  public trackId: string = '';
  public url = [];
  public rideCompleteImage: string = '../../../assets/img/track_unavailable.svg';
  public errorImage: string = '../../../assets/img/error-screen.png';
  public screenOrientationImage: string = '../../../assets/img/rotate.png';
  constructor(private _shareRideHomeService: ShareRideHomeService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.trackId = this.route.snapshot.params.id;
    this.fetchTrackingData(this.trackId);
  }
  fetchTrackingData(id) {
    this._shareRideHomeService.getTrackingData(id).subscribe((result) => {
      this.showData = true;
      this.data = result;
      this.data.setMapCenter = true;
      this.subscriber = Observable.interval(5000).subscribe(() => {
        this._shareRideHomeService.getTrackingData(id).subscribe((result) => {

          this.data = result;
          this.data.setMapCenter = false;
        },
          (err) => {
            if (err.error && err.error.message && err.error.message.info) {
              console.log(err);
              this.showData = false;
              this.subscriber.unsubscribe();
            }
          });
      });
    },
      (err) => {
        this.showData = false;
        console.log('Error', err);
      });

  }
  handleOrientationChange(evt: any) {
    if (window.orientation === 0 || window.orientation === 180) {
      this.reloadData(evt);
    }
  }
  reloadData(evt: any) {
    if (this.trackId) {
      this._shareRideHomeService.getTrackingData(this.trackId).subscribe((result) => {
        this.showData = true;
        this.data = result;
        this.data.setMapCenter = true;
      },
      (err) => {
        if (err.error && err.error.message) {
          this.showData = false;
        }
      });
    }
  }
  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }
}
