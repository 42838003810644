import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, EventEmitter, Output } from '@angular/core';
import {Observable} from 'rxjs/Rx';
import { RIDE_TYPES } from '../shared/constants';
@Component({
  selector: 'app-live-status-update',
  template: require('./live-status-update.component.html')
})
export class LiveStatusUpdateComponent implements OnInit, OnChanges, OnDestroy {
  @Input('data') data: any;
  @Output() reload: EventEmitter<any> = new EventEmitter();
  public name: string;
  public number: string;
  public currentTime: Date = new Date();
  public updatedTime: Date = new Date();
  public lastUpdated = 0;
  public status: string;
  public eta = 0;
  public subscriber: any;
  public ownerName: string;
  public customerName: string;
  public rideOrOrder: string;
  public dataLoaded = false;
  public stauses = ['onTheWay', 'arrived', 'started', 'reached'];
  public bookForSomeoneElse: any;
  public orderType: string;

  constructor() { }

  ngOnInit() {
    this.getLiveStatus(this.data);
    this.subscriber = Observable.interval(500).subscribe(() => {
      this.currentTime = new Date();
      this.lastUpdated = this.currentTime.getMinutes() - this.updatedTime.getMinutes();
      this.lastUpdated = Math.abs(this.lastUpdated);
    });
    this.reloadData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      if (changes.data.currentValue && changes.data.currentValue.riderObj) {
        this.getLiveStatus(changes.data.currentValue);
      }
    }
  }

  getLiveStatus(data) {
    this.dataLoaded = true;
    if (data) {
      this.name = data.riderObj.riderInfo.name;
      this.bookForSomeoneElse = data.bookForSomeoneElse;
      this.customerName = this.bookForSomeoneElse ? data.bookForSomeoneElse.customer.name : data.customerObj.name;
      this.ownerName = RIDE_TYPES.includes(data.orderType) ? this.customerName : 'Captain';
      this.rideOrOrder = RIDE_TYPES.includes(data.orderType) ? 'ride' : 'order';
      this.status = data.status;
      this.eta = data.riderLocation && data.riderLocation.eta || this.eta;
      this.updatedTime = new Date();
      this.orderType = data.orderType
      document.title = this.customerName ? this.customerName + '\'s Rapido' : 'Rapido';
    }
  }

  reloadData() {
    this.reload.emit(true);
  }
  ngOnDestroy() {
    this.subscriber.unsubscribe();
  }

}
